<template>
  <div>
    <div class="info">
      Twoja płatność jest w trakcie przetwarzania. Jeżeli anulowałeś płatność,
      wykonaj ją jeszcze raz.
    </div>
    <div class="sheet-button">
      <a href="#" @click="newPayment()">Zapłać</a>
    </div>
  </div>
</template>

<script>
import PaymentService from "../services/payment.service";
export default {
  name: "Payment",
  created() {
    PaymentService.getPaymentStatus().then(
      response => {
        var content = response.data;
        if (content["paymentStatus"] && content["paymentStatus"] == "paid") {
          this.$router.push("/sheet");
        }
      },
      error => {
        this.message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    newPayment: function() {
      PaymentService.getPaymentData().then(
        response => {
          var content = response.data;
          PaymentService.initPayment(
            content,
            this.$store.state.auth.user.email
          );
        },
        error => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.sheet {
  &-button {
    display: block;
    margin: 60px auto 45px;
    text-align: center;
    a {
      max-width: 130px;
      background-color: #343a40;
      box-shadow: 0 1px 1px #ccc;
      border-radius: 2px;
      display: inline-block;
      margin: 0 5px;
    }
  }
}

a {
  display: inline-block;
  padding: 18px 30px;
  color: #fff !important;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none !important;
  line-height: 1;
  text-transform: uppercase;
  background-color: transparent;

  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
}

.info {
  margin: 50px auto 0;
  text-align: center;

  span {
    font-size: 20px;
  }
}
</style>
